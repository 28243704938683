import React, { useState } from "react";
import SearchTypeSelect from "./SearchTypeSelect";
import ShopifyOrderIdSearch from "./ShopifyOrderIdSearch";
import EmailAndInvoiceSearch from "./EmailAndInvoiceSearch";

const SearchForm = ({ onSubmit }) => {
  const [searchType, setSearchType] = useState("order_id");
  const [searchInput, setSearchInput] = useState({
    shopify_order_id: "",
    email: "",
    invoiceNumber: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    let fields = {};
    if (searchType === "order_id") {
      fields = {
        shop_order_id: searchInput.shopify_order_id,
      };
    } else {
      fields = {
        email: searchInput.email,
        invoice_number: searchInput.invoiceNumber,
      };
    }
    const body = {
      search_strategy: searchType,
      fields: fields,
    };
    onSubmit(body);
  };

  return (
    <div className="flex flex-items">
      <form onSubmit={handleSubmit}>
        <div className="flex items-center border border-gray-325 px-4 h-10 py-2 bg-white box-border rounded-lg ml-4">
          <SearchTypeSelect value={searchType} onChange={(e) => setSearchType(e.target.value)} />
        </div>
        {searchType === "order_id" ? (
          <ShopifyOrderIdSearch
            value={searchInput.order_id}
            onChange={(e) => setSearchInput({
              ...searchInput,
              shopify_order_id: e.target.value,
            })} />

        ) : (
          <EmailAndInvoiceSearch
            email={searchInput.email}
            invoiceNumber={searchInput.invoiceNumber}
            onChange={setSearchInput}
          />
        )}
        <button type="submit"
                className="mt-5 ml-4 bg-gradient-to-r from-blue-500 to-blue-700 text-white font-bold py-2 px-4 rounded shadow-md hover:shadow-lg transition duration-200 ease-in-out">
          Search
        </button>

      </form>
    </div>
  );
};

export default SearchForm;
