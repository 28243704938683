import React from 'react';

const SearchTypeSelect = ({ value, onChange }) => (
  <select
    className="font-medium text-xs leading-4 text-gray-525 focus:outline-none w-full flex items-center"
    value={value}
    onChange={onChange}
  >
    <option value="order_id">Find by Shopify Order ID</option>
    <option value="email_and_order_number">Find By Email and Order Number</option>
  </select>
);

export default SearchTypeSelect;
