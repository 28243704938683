import React from "react";
import AsyncSelect from "react-select/async";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import Header from "../header/index";
import SubHeaderOld from "../common/SubHeaderOld";
import { useSuperAdmin } from "./superadmin.hook";
import { Shield } from "../ui-kit/icons/shield";
import translationDE from "../../translations/superadmin/translation_de.json";
import translationEN from "../../translations/superadmin/translation_en.json";
import Confirmation from "../confirmation_modal/confirmation_modal";
import { MerchantSetting } from "./components/MerchantSetting";
import { InputLabel } from "../../ui-kit/components/InputLabel/InputLabel";
import { Input } from "../../ui-kit/components/Input/Input";

const SuperAdmin = ({
  currentMerchant,
  currentUser,
  paywallToggleDisplayed,
}) => {
  const {
    selectChangeHandler,
    state,
    paywallState,
    toggleSettingsPaywall,
    checkoutV3State,
    toggleSettingsCheckoutV3,
    exchangeReturnsState,
    toggleSettingsExchangeReturns,
    customReturnsState,
    toggleSettingsCustomReturns,
    klaviyoIntegrationState,
    toggleSettingsKlaviyoIntegration,
    xentralIntegrationState,
    toggleSettingsXentralIntegration,
    peoplevoxIntegrationState,
    toggleSettingsPeoplevoxIntegration,
    onStoreExchangeState,
    toggleSettingsOnStoreExchange,
    createManualReturnState,
    toggleSettingsCreateManualReturnState,
    loadOptionsDebounce,
    updateMerchant,
    resetWhatIsNewBadge,
    confirmWhatIsNewBadge,
    onboardingAssistantVisibilityDays,
    updateOnboardingAssistantState,
    finecomIntegrationState,
    toggleSettingsFinecomIntegration,
  } = useSuperAdmin({ currentMerchant });
  const t = currentUser.locale === "en" ? translationEN : translationDE;

  return (
    <div className="w-full font-inter">
      <ReactNotification className="left-0 right-0 m-auto" />
      <Header current_user={currentUser} title={t.admin_area} />
      <SubHeaderOld text={t.admin_area} Icon={Shield} />
      <div className="p-8">
        <div className="w-full mb-8">
          <div className="mb-3">
            <div className="font-semibold text-base leading-6 text-gray-825 mb-2">
              {t.account_merchant}
            </div>
            <div className="font-medium text-xs leading-4 text-gray-725">
              {t.change_merchant}
              <br />
              {t.beware_text}
            </div>
          </div>

          <div className="flex items-center">
            <div className="w-1/3">
              <AsyncSelect
                className="bg-white box-border border-gray-750 rounded-lg text-xs px-4 leading-4 border focus:outline-none py-px px-px font-medium text-gray-750 w-full focus:border-blue-675 focus:border mr-6"
                cacheOptions
                loadOptions={loadOptionsDebounce}
                noOptionsMessage={() => null}
                onChange={(data) => selectChangeHandler(data)}
                defaultValue={state.options}
              />
            </div>
            <button
              className="bg-green-800 rounded-lg ml-4 pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
              onClick={updateMerchant}
            >
              {t.save}
            </button>
          </div>
        </div>
        <div className="w-full mb-8">
          <div className="mb-3">
            <div className="font-semibold text-base leading-6 text-gray-825 mb-2">
              {t.what_is_new_badge}
            </div>
            <div className="flex items-center">
              <div className="font-medium text-xs leading-4 text-gray-725 w-1/3">
                {t.badge_description}
              </div>
              <button
                className="bg-green-800 rounded-lg ml-4 pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
                onClick={resetWhatIsNewBadge}
              >
                {t.reset}
              </button>
            </div>
          </div>
        </div>
        <Confirmation
          isOpen={state.whatIsBadgeConfirmation}
          onRequestClose={resetWhatIsNewBadge}
          confirmation={confirmWhatIsNewBadge}
          confirmationTitle={t.confirmation_title}
          confirmationDescription={t.confirmation_description}
          cancelText={t.cancel}
          confirmText={t.confirm}
        />

        <div className="w-full mb-8">
          <div className="mb-3">
            <div className="font-semibold text-base leading-6 text-gray-825 mb-2">
              Merchant Settings
            </div>

            <div className="w-492px flex flex-col">
              {paywallToggleDisplayed && (
                <MerchantSetting
                  settingsLabel="Paywall"
                  settingsEnabled={paywallState}
                  toggleSettings={toggleSettingsPaywall}
                />
              )}

              <MerchantSetting
                settingsLabel="Checkout V3"
                settingsEnabled={checkoutV3State}
                toggleSettings={toggleSettingsCheckoutV3}
                enableOnce
              />

              {currentMerchant.shop_backend !== "shopware_integration" && (
                <MerchantSetting
                  settingsLabel="Exchange Returns"
                  settingsEnabled={exchangeReturnsState}
                  toggleSettings={toggleSettingsExchangeReturns}
                />
              )}

              <MerchantSetting
                settingsLabel="Custom Returns"
                settingsEnabled={customReturnsState}
                toggleSettings={toggleSettingsCustomReturns}
              />

              <MerchantSetting
                settingsLabel="Klaviyo"
                settingsEnabled={klaviyoIntegrationState}
                toggleSettings={toggleSettingsKlaviyoIntegration}
              />

              <MerchantSetting
                settingsLabel="Xentral"
                settingsEnabled={xentralIntegrationState}
                toggleSettings={toggleSettingsXentralIntegration}
              />

              <MerchantSetting
                settingsLabel="Peoplevox"
                settingsEnabled={peoplevoxIntegrationState}
                toggleSettings={toggleSettingsPeoplevoxIntegration}
              />

              <MerchantSetting
                settingsLabel="Shop Exchange"
                settingsEnabled={onStoreExchangeState}
                toggleSettings={toggleSettingsOnStoreExchange}
              />

              <MerchantSetting
                settingsLabel="+ Create Manual Return"
                settingsEnabled={createManualReturnState}
                toggleSettings={toggleSettingsCreateManualReturnState}
              />

              <MerchantSetting
                settingsLabel="Finecom"
                settingsEnabled={finecomIntegrationState}
                toggleSettings={toggleSettingsFinecomIntegration}
              />

              <div className="flex items-center mt-0">
                <div className="">
                  <InputLabel>
                    {"Onboarding Assistant Visiblity Days"}
                  </InputLabel>
                  <Input
                    className="placeholder-gray-875"
                    name="onboardingAssistantVisibilityDays"
                    onChange={(value) => updateOnboardingAssistantState(value)}
                    value={onboardingAssistantVisibilityDays}
                    placeholder={
                      "No of days from subscription date onboarding assistant would be visible"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdmin;
