import React from "react";
import Modal from "react-modal";
import ReactNotification from "react-notifications-component";
import moment from "moment";
import classNames from "classnames";
import { isEmpty } from "lodash";

import { SearchIcon } from "../../../ui-kit/icons/SearchIcon";
import { PreviousArrowIcon } from "../../../ui-kit/icons/PreviousArrowIcon";
import { NextArrowIcon } from "../../../ui-kit/icons/NextArrowIcon";
import { ModelCross } from "../../../ui-kit/icons/ModelCross";

import { SuccessToast } from "../../common/SuccessToast";
import { ErrorToast } from "../../common/ErrorToast";

import { getCountryList } from "../../../util/getCountryList";

import { ReturnStatus } from "./components/ReturnStatus";
import { ShowReturnItem } from "./components/ShowReturnItem";
import { SaveNote } from "./components/SaveNote";
import { ReturnHistory } from "./components/ReturnHistory";
import { SideBar } from "./components/SideBar";
import { CreateLabelModal } from "./components/CreateLabelModal";
import { ReturnCloseModal } from "./components/ReturnCloseModal/ReturnCloseModal";
import { InstantStoreCreditAuthorizedPaymentModal } from "./components/InstantStoreCreditAuthorizedPaymentModal";
import { InstantStoreCreditCaptureAuthorizedPaymentModal } from "./components/InstantStoreCreditCaptureAuthorizedPaymentModal";

import translationEN from "../../../translations/manageReturns/translationEN.json";
import translationDE from "../../../translations/manageReturns/translationDE.json";

import "react-notifications-component/dist/theme.css";

Modal.setAppElement("#root");

export class EditReturn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      customer_return: this.props.customer_return,
      showModal: false,
      showImageModal: false,
      imageUrl: "",
      countryOptions: getCountryList(),
      customer: {
        id: this.props.customer_return.customer?.id,
        full_name: this.props.customer_return.customer?.full_name,
        email: this.props.customer_return.customer?.email,
      },
      return_label_customer: {
        full_name: this.props.customer_return.customer?.shipping_full_name,
        address1: this.props.customer_return.customer?.address1,
        street_number: this.props.customer_return.customer?.street_number,
        city: this.props.customer_return.customer?.city,
        zip: this.props.customer_return.customer?.zip,
        country: getCountryList().find(
          (a) => a.label === this.props.customer_return.customer?.country
        )?.value,
        first_name: this.props.customer_return.customer?.shipping_first_name,
        last_name: this.props.customer_return.customer?.shipping_last_name,
      },
      activities: this.props.customer_return.customer_return_activities,
      return_address: {
        dhl_receiver_id: this.props.merchant.dhl_receiver_id,
        full_name: this.props.return_address?.full_name,
        address1: this.props.return_address?.address1,
        street_number: this.props.return_address?.street_number,
        city: this.props.return_address?.city,
        country: this.props.return_address?.country,
        first_name: this.props.return_address?.name,
        last_name: this.props.return_address?.name2,
        zip: this.props.return_address?.zip,
        country_iso: this.props.return_address?.country_iso,
      },
      edit_dhl_receiver_id: false,
      edit_customer: false,
      edit_return_address: false,
      t:
        this.props.current_user.locale === "en" ? translationEN : translationDE,
      country_iso: this.props.customer_return.customer?.country,
      showCloseReturnModal: false,
      create_dhl_label_spinner: false,
      instantStoreCreditCaptureAuthorizedPaymentModal: false,
      instantStoreCreditCaptureAuthorizedPaymentModalOkButtonName: "",
      instantStoreCreditCaptureAuthorizedPaymentModalOkButtonCallback: () => {},
      instantStoreCreditAuthorizedPaymentModal: false,
      label_type: this.props.label_type,
    };
    this.url = sessionStorage.getItem("URL") || "/customer_returns";
  }

  componentDidMount() {
    this.checkReturnStatusPeriodicallyUntilCompletion();

    const data = JSON.stringify({ message: "rdpLoaded" });
    window.parent.postMessage(data, "*");
  }

  markStatus = (e, id) => {
    let body;
    const commit = e.target.name;

    if (commit === "complete_return") {
      window.location.href = `/customer_returns/${this.state.customer_return.id}/refund`;
    } else if (commit === "item_condition_id") {
      body = {
        commit,
        customer_return: {
          items_attributes: [{ id, item_condition_id: e.target.value }],
        },
      };
    } else if (commit === "item_return_type") {
      body = {
        commit,
        customer_return: {
          items_attributes: [{ id, return_type: e.target.value }],
        },
      };
    } else if (
      commit === "mark_as_missing" ||
      commit === "undo_mark_as_missing"
    ) {
      body = {
        commit,
        missing_item: id,
        customer_return: {
          notes: this.state.customer_return.notes,
        },
        id: this.state.customer_return.id,
      };
    } else if (commit === "select") {
      body = {
        [e.target.value]: "undo_arrived_inspected",
        customer_return: {
          notes: this.state.customer_return.notes,
        },
        id: this.state.customer_return.id,
      };
    } else {
      body = {
        commit,
        customer_return: {
          notes: this.state.customer_return.notes,
        },
        id: this.state.customer_return.id,
      };
    }

    if (commit !== "complete_return") {
      fetch(`/customer_returns/${this.state.customer_return.id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify(body),
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((responseJson) => {
            this.setState({
              customer_return: responseJson,
              activities: responseJson.customer_return_activities,
            });
            SuccessToast({
              toastId: "customer_return",
              message: "Saved successfully!",
            });

            if (commit === "close") {
              this.setState({
                instantStoreCreditAuthorizedPaymentModal:
                  responseJson.instant_store_credit_authorized_amount_popup_eligible,
              });
            }
          });
        } else {
          response.json().then((responseJson) => {
            ErrorToast({
              toastId: "customer_return",
              message: responseJson.error,
            });
          });
        }

        this.closeReturnModal();
        this.closeInstantStoreCreditCaptureAuthorizedPaymentModal();
      });
    }
  };

  searchInputHandler = (e) => {
    this.setState({ searchInput: e.target.value.trim() });
  };

  search = (e) => {
    e.preventDefault();

    if (isEmpty(this.state.searchInput)) return;

    fetch(`/customer_returns/search_returns?search=${this.state.searchInput}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          if (responseJson.customer_returns.length > 1) {
            window.location = `/customer_returns?search=${this.state.searchInput}`;
          } else if (responseJson.customer_returns.length === 0) {
            ErrorToast({
              toastId: "customer_return",
              message: "Return not Found",
            });
          } else if (responseJson.customer_returns.length === 1) {
            this.setStateOfCustomerReturn(responseJson.customer_returns[0]);
          }
        });
      } else {
        response.json().then((responseJson) => {
          console.log(
            responseJson.error || response.status + " " + response.statusText
          );
        });
      }
    });
  };
  inputChangeHandler = ({ name, value }) => {
    this.setState({
      customer_return: {
        ...this.state.customer_return,
        [name]: value,
      },
    });
  };
  showModelHandler = () => {
    this.setState({
      showModal: true,
      create_dhl_label_spinner: false,
    });
    document.body.style.overflow = "hidden";
  };
  ModelClose = () => {
    this.setState({
      showModal: false,
      return_label_customer: {
        full_name: this.state.customer_return.customer?.shipping_full_name,
        address1: this.state.customer_return.customer?.address1,
        street_number: this.state.customer_return.customer?.street_number,
        city: this.state.customer_return.customer?.city,
        zip: this.state.customer_return.customer?.zip,
        country: this.state.countryOptions.find(
          (a) => a.label === this.state.customer_return.customer?.country
        )?.value,
        first_name: this.state.customer_return.customer?.shipping_first_name,
        last_name: this.state.customer_return.customer?.shipping_last_name,
      },
      return_address: {
        dhl_receiver_id: this.props.merchant.dhl_receiver_id,
        full_name: this.state.return_address?.full_name,
        address1: this.state.return_address?.address1,
        street_number: this.state.return_address?.street_number,
        city: this.state.return_address?.city,
        country: this.state.countryOptions.find(
          (a) => a.label === this.state.return_address?.country
        )?.value,
        first_name: this.state.return_address?.first_name,
        last_name: this.state.return_address?.last_name,
        zip: this.state.return_address?.zip,
        country_iso: this.state.return_address?.country,
      },
      edit_customer: false,
      edit_dhl_receiver_id: false,
      country_iso: this.state.customer_return.customer?.country,
      create_dhl_label_spinner: false,
      label_type: this.state.label_type,
    });
    document.body.style.overflow = "auto";
  };
  labelCustomerInputHandler = ({ name, value }) => {
    this.setState({
      return_label_customer: {
        ...this.state.return_label_customer,
        [name]: value.trim(),
      },
    });
  };
  labelReturnAddressInputHandler = ({ name, value }) => {
    this.setState({
      return_address: {
        ...this.state.return_address,
        [name]: value,
      },
    });
  };
  editDhlReceiverId = (e) => {
    this.setState({
      edit_dhl_receiver_id: true,
      edit_customer: false,
      edit_return_address: false,
    });
  };
  editCustomer = (e) => {
    this.setState({
      edit_customer: true,
      edit_dhl_receiver_id: false,
      edit_return_address: false,
    });
  };
  editReturnAddress = (e) => {
    this.setState({
      edit_customer: false,
      edit_dhl_receiver_id: false,
      edit_return_address: true,
    });
  };
  labelTypeHandler = ({ value }) => {
    this.setState({
      label_type: value,
    });
  };
  createLable = () => {
    let body = {
      customer: this.state.return_label_customer,
      return_address: this.state.return_address,
      label_type: this.state.label_type,
    };
    this.setState({
      create_dhl_label_spinner: true,
    });
    fetch(
      `/customer_returns/${this.state.customer_return.id}/create_shipment`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify(body),
      }
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          this.setState({
            customer_return: responseJson,
            activities: responseJson.customer_return_activities,
            showModal: false,
          });
          SuccessToast({
            toastId: "customer_return",
            message: "Label Created Successfully",
          });
          document.body.style.overflow = "auto";
        });
      } else {
        response.json().then((responseJson) => {
          ErrorToast({
            toastId: "customer_return",
            message: responseJson.error,
          });
          this.setState({
            create_dhl_label_spinner: false,
          });
        });
      }
    });
  };
  sendEmail = () => {
    let body = {
      id: this.state.customer_return.id,
    };
    fetch(
      `/customer_returns/${this.state.customer_return.id}/send_shipment_label_email`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify(body),
      }
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          this.setState({
            activities: responseJson.customer_return_activities,
          });
          SuccessToast({
            toastId: "customer_return",
            message: "Email Sent Successfully.",
          });
        });
      } else {
        response.json().then((responseJson) => {
          ErrorToast({
            toastId: "customer_return",
            message: responseJson.error,
          });
        });
      }
    });
  };

  nextReturn = () => {
    fetch(
      `/customer_returns/next_return?id=${
        this.state.customer_return.id
      }&status=${this.state.customer_return.status}&order=${
        sessionStorage.getItem("order_by_date")
          ? sessionStorage.getItem("order_by_date")
          : ""
      }`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          this.setStateOfCustomerReturn(responseJson);
          window.history.pushState(
            "Object",
            "title",
            `/customer_returns/${responseJson.id}/edit`
          );
        });
      } else {
        response.json().then((responseJson) => {
          ErrorToast({
            toastId: "customer_return",
            message: responseJson.error,
          });
        });
      }
    });
  };

  showImageModal = (imageUrl) => {
    this.setState({
      imageUrl,
      showImageModal: true,
    });
  };

  closeImageModal = () => {
    this.setState({
      showImageModal: false,
      imageUrl: "",
    });
  };

  previousReturn = () => {
    fetch(
      `/customer_returns/previous_return?id=${
        this.state.customer_return.id
      }&status=${this.state.customer_return.status}&order=${
        sessionStorage.getItem("order_by_date")
          ? sessionStorage.getItem("order_by_date")
          : ""
      }`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          this.setStateOfCustomerReturn(responseJson);
          window.history.pushState(
            "Object",
            "title",
            `/customer_returns/${responseJson.id}/edit`
          );
        });
      } else {
        response.json().then((responseJson) => {
          ErrorToast({
            toastId: "customer_return",
            message: responseJson.error,
          });
        });
      }
    });
  };

  setStateOfCustomerReturn = (customer_return) => {
    this.setState({
      customer_return: customer_return,
      activities: customer_return.customer_return_activities,
      customer: {
        id: customer_return.customer?.id,
        full_name: customer_return.customer?.full_name,
        email: customer_return.customer?.email,
      },
      return_label_customer: {
        full_name: customer_return.customer?.shipping_full_name,
        address1: customer_return.customer?.address1,
        street_number: customer_return.customer?.street_number,
        city: customer_return.customer?.city,
        zip: customer_return.customer?.zip,
        country: this.state.countryOptions.find(
          (a) => a.label === customer_return.customer?.country
        )?.value,
        first_name: customer_return.customer?.shipping_first_name,
        last_name: customer_return.customer?.shipping_last_name,
      },
      country_iso: customer_return.customer?.country,
    });
  };

  closeReturnModal = () => {
    this.setState({ showCloseReturnModal: false });
    document.body.style.overflow = "auto";
  };

  showCloseReturnModal = () => {
    this.setState({ showCloseReturnModal: true });
    document.body.style.overflow = "hidden";
  };

  backEditLabel = (e) => {
    if (e.target.name === "back_edit_customer") {
      this.setState({
        return_label_customer: {
          full_name: this.state.customer_return.customer?.shipping_full_name,
          address1: this.state.customer_return.customer?.address1,
          street_number: this.state.customer_return.customer?.street_number,
          city: this.state.customer_return.customer?.city,
          zip: this.state.customer_return.customer?.zip,
          country: this.state.countryOptions.find(
            (a) => a.label === this.state.customer_return.customer?.country
          )?.value,
          first_name: this.state.customer_return.customer?.shipping_first_name,
          last_name: this.state.customer_return.customer?.shipping_last_name,
        },
        edit_customer: false,
        edit_dhl_receiver_id: false,
        edit_return_address: false,
      });
    } else if (e.target.name == "back_edit_dhl_receiver_id") {
      this.setState({
        return_address: {
          dhl_receiver_id: this.props.merchant.dhl_receiver_id,
        },
        edit_customer: false,
        edit_dhl_receiver_id: false,
        edit_return_address: false,
      });
    } else if (e.target.name == "back_edit_return_address") {
      this.setState({
        return_address: {
          full_name: this.state.return_address.full_name,
          address1: this.state.return_address.address1,
          street_number: this.state.return_address.street_number,
          city: this.state.return_address.city,
          country: this.state.return_address.country,
          first_name: this.state.return_address.first_name,
          last_name: this.state.return_address.last_name,
          zip: this.state.return_address.zip,
          country_iso: this.state.return_address.country_iso,
        },
        edit_customer: false,
        edit_dhl_receiver_id: false,
        edit_return_address: false,
      });
    }
  };
  saveEditLabel = (e) => {
    this.setState({
      edit_customer: false,
      edit_dhl_receiver_id: false,
      edit_return_address: false,
    });

    if (e.target.name == "save_customer_changes") {
      const { first_name, last_name } = this.state.return_label_customer;
      const shipping_full_name = [first_name.trim(), last_name.trim()]
        .filter(Boolean)
        .join(" ");
      this.setState({
        return_label_customer: {
          ...this.state.return_label_customer,
          full_name: shipping_full_name,
        },
      });
    } else if (e.target.name == "save_return_address_changes") {
      const { first_name, last_name } = this.state.return_address;
      const full_name = [first_name.trim(), last_name.trim()]
        .filter(Boolean)
        .join(" ");
      this.setState({
        return_address: {
          ...this.state.return_address,
          full_name: full_name,
        },
      });
    }
  };
  selectCountry = (e) => {
    this.setState({
      return_label_customer: {
        ...this.state.return_label_customer,
        country: e.target.value,
      },
      country_iso: e.target.selectedOptions[0]?.text,
    });
  };

  selectReturnAddressCountry = (e) => {
    this.setState({
      return_address: {
        ...this.state.return_address,
        country: e.target.value,
        country_iso: e.target.selectedOptions[0]?.text,
      },
    });
  };

  formatActivityTime = (value) => {
    const date = new Date(value);

    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  labelFieldsPresent = () => {
    const { first_name, address1, city, zip, country } =
      this.state.return_label_customer;
    return first_name && address1 && city && zip && country;
  };

  primaryAction = () => {
    return !this.state.customer_return.is_inspected &&
      !this.state.customer_return.is_arrived
      ? "mark_as_arrived"
      : this.state.customer_return.is_inspected &&
        this.state.customer_return.is_arrived
      ? "complete_return"
      : "mark_as_inspected";
  };

  hideMarkItemAsMissing = (item) => {
    return this.state.customer_return.is_inspected || item.is_cancelled;
  };

  syncReturnStatus = async () => {
    const response = await fetch(location.pathname, {
      headers: {
        Accept: "application/json",
      },
    });
    if (response.status >= 300) return;

    const responseJSON = await response.json();
    this.setState({
      customer_return: responseJSON,
      activities: responseJSON.customer_return_activities,
    });
  };

  isHidePrimaryActionButton = () => {
    return (
      (this.state.customer_return.is_arrived &&
        this.props.cannot_mark_status_beyond_arrived_on_rdp) ||
      this.isCancelled() ||
      this.isCompleted() ||
      this.isCompletionInProgress() ||
      this.isHideCompleteReturnButton()
    );
  };

  isHideCompleteReturnButton = () => {
    if (this.isOnHold()) return true;
    if (!this.isInspected()) return;

    return (
      this.props.cannot_complete_return || this.isReturnDisconnectedFromShop()
    );
  };

  shouldHideCompleteReturnAction = () => {
    const warehouse2User = this.props.current_user.role === "warehouse2";
    const completed =
      this.state.customer_return.is_refunded ||
      this.state.customer_return.is_closed;

    return (
      this.state.customer_return.is_arrived &&
      this.state.customer_return.is_inspected &&
      !completed &&
      !this.state.customer_return.is_cancelled &&
      (this.state.customer_return.on_hold || warehouse2User)
    );
  };

  isCancelled = () => {
    return this.state.customer_return.status === "cancelled";
  };

  isOnHold = () => {
    return this.state.customer_return.status === "on_hold";
  };

  isCompleted = () => {
    return this.state.customer_return.status === "completed";
  };

  isInspected = () => {
    return this.state.customer_return.status === "needs_review";
  };

  isCompletionInProgress = () => {
    return this.state.customer_return.completion_in_progress;
  };

  isReturnDisconnectedFromShop = () => {
    return isEmpty(this.state.customer_return.shop_order_id);
  };

  checkReturnStatusPeriodicallyUntilCompletion = () => {
    if (!this.isCompletionInProgress()) return;

    SuccessToast({
      toastId: "shopifyReturnInProgress",
      message: this.state.t.return_completion_in_progress_msg,
      autoCloseDuration: 10000,
    });

    const cron = setInterval(async () => {
      if (!this.isCompletionInProgress()) {
        clearInterval(cron);
      }

      await this.syncReturnStatus();
    }, 3000);
  };

  closeInstantStoreCreditCaptureAuthorizedPaymentModal = () => {
    this.setState({ instantStoreCreditCaptureAuthorizedPaymentModal: false });
    document.body.style.overflow = "auto";
  };

  closeInstantStoreCreditAuthorizedPaymentModal = () => {
    this.setState({ instantStoreCreditAuthorizedPaymentModal: false });
    document.body.style.overflow = "auto";
  };

  showInstantStoreCreditCaptureAuthorizedPaymentModal = ({
    actionName,
    actionCallback,
  }) => {
    this.setState({
      instantStoreCreditCaptureAuthorizedPaymentModal: true,
      instantStoreCreditCaptureAuthorizedPaymentModalOkButtonName: actionName,
      instantStoreCreditCaptureAuthorizedPaymentModalOkButtonCallback:
        actionCallback,
    });

    document.body.style.overflow = "hidden";
  };

  render() {
    const showSendEmail =
      (this.props.merchant.shipment_label_template?.is_active &&
        this.state.customer_return.shipments.some((s) => s.label_url)) ||
      this.props.merchant.track_klaviyo_integration;

    return (
      <div className="font-inter">
        <ReactNotification className="left-0 right-0 m-auto" />

        <div className="flex justify-between items-center px-8 pb-3">
          <div className="font-medium text-xs leading-4">
            <p>
              <span className="text-gray-525">
                <a href={this.url} className="focus:outline-none" accessKey="E">
                  {this.state.t.manage_return}
                </a>{" "}
                /{" "}
              </span>

              <span className="text-gray-725 text-xs13">
                {this.state.customer_return.order_name
                  ? this.state.customer_return.order_name
                  : this.state.customer_return.order_number}
              </span>
            </p>
          </div>

          <div className="font-inter">
            <a className="focus:outline-none" href="/users/edit">
              <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
                {`${this.props.current_user.first_name} ${this.props.current_user.last_name}`}
              </p>

              <p className="font-normal text-xs leading-4 text-right text-gray-625">
                {this.props.current_user.email}
              </p>
            </a>
          </div>
        </div>

        <div>
          <div className="border-t border-b px-8 flex justify-between items-center py-5 border-gray-225">
            <div className="flex items-center w-1/2">
              <p className="font-semibold text-base leading-6 text-gray-825">
                {this.state.customer_return.order_name ||
                  this.state.customer_return.order_number}
              </p>

              {!this.isHidePrimaryActionButton() && (
                <button
                  className={classNames(
                    "bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 ml-8 font-semibold text-sm leading-5 text-white border",
                    "border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
                  )}
                  accessKey="R"
                  name={this.primaryAction()}
                  onClick={this.markStatus}
                >
                  {this.state.t[this.primaryAction()]}
                </button>
              )}

              {this.state.customer_return.on_hold &&
                !this.state.customer_return.is_cancelled &&
                !this.state.customer_return.is_closed && (
                  <div className="flex items-center">
                    <p className="font-normal text-xs leading-4 text-gray-825 ml-4 mr-8">
                      {this.state.t.marked_as_on_hold}{" "}
                      {moment(this.state.customer_return.hold_date).format(
                        "MMM DD YYYY"
                      )}
                    </p>
                    <button
                      className={`bg-blue-675 rounded-lg font-semibold text-sm leading-5 text-white px-4 py-2 focus:outline-none hover:bg-blue-625`}
                      name="solve_case"
                      onClick={this.markStatus}
                    >
                      {this.state.t.solve_case}
                    </button>
                  </div>
                )}
            </div>

            {this.props.can_browse_other_returns_from_rdp && (
              <div className="flex items-center w-1/2 float-right justify-end">
                <div className="flex items-center px-5 py-2 border border-gray-325 bg-white box-border rounded-lg w-2/3 float-right focus:border-blue-675 focus:border">
                  <SearchIcon />
                  <form
                    className="font-medium text-xs leading-4 text-gray-525 ml-2 w-full"
                    onSubmit={this.search}
                  >
                    <input
                      accessKey="W"
                      className="outline-none w-full"
                      name="search_value"
                      type="text"
                      placeholder={this.state.t.search_for_a_return}
                      onChange={this.searchInputHandler}
                    ></input>
                  </form>
                </div>

                <div
                  className="border cursor-pointer border-gray-325 ml-3 p-2 rounded shadow-custom_shaddow3"
                  onClick={this.previousReturn}
                >
                  <PreviousArrowIcon />
                </div>

                <div
                  className="border cursor-pointer border-gray-325 ml-3 p-2 rounded shadow-custom_shaddow3"
                  onClick={this.nextReturn}
                >
                  <NextArrowIcon />
                </div>
              </div>
            )}
          </div>

          <div className="flex px-8">
            <div className="pr-6 border-r w-67% pt-8 min-h-screen">
              <p className="font-semibold text-base leading-6 text-gray-825">
                Timeline
              </p>
              <ReturnStatus
                registered_date={this.state.customer_return.registered_date}
                arrived_date={this.state.customer_return.arrived_date}
                inspected_date={this.state.customer_return.inspected_date}
                refunded_date={this.state.customer_return.refunded_date}
                is_arrived={this.state.customer_return.is_arrived}
                is_inspected={this.state.customer_return.is_inspected}
                is_refunded={this.state.customer_return.is_refunded}
                t={this.state.t}
              />

              <ShowReturnItem
                isScayleIntegration={this.props.is_scayle_integration}
                customer_return={this.state.customer_return}
                items={this.state.customer_return.items}
                isItemConditionDisplayable={
                  this.props.is_item_condition_displayable
                }
                itemConditions={this.props.item_conditions}
                itemReturnTypes={this.props.return_types}
                t={this.state.t}
                currency={this.state.customer_return.currency}
                is_refunded={this.state.customer_return.is_refunded}
                is_cancelled={this.state.customer_return.is_cancelled}
                showImageModal={this.showImageModal}
                hideMarkItemAsMissing={this.hideMarkItemAsMissing}
                markStatus={this.markStatus}
                showInstantStoreCreditCaptureAuthorizedPaymentModal={
                  this.showInstantStoreCreditCaptureAuthorizedPaymentModal
                }
                canCancel={this.props.can_cancel}
                isCompletionInProgress={this.isCompletionInProgress()}
              />

              <SaveNote
                t={this.state.t}
                inputChangeHandler={this.inputChangeHandler}
                notes={this.state.customer_return.notes}
                markStatus={this.markStatus}
              />

              <ReturnHistory
                activities={this.state.activities}
                t={this.state.t}
                formatActivityTime={this.formatActivityTime}
              />
            </div>

            <SideBar
              t={this.state.t}
              merchant={this.props.merchant}
              customer_return={this.state.customer_return}
              markStatus={this.markStatus}
              showModelHandler={this.showModelHandler}
              customer={this.state.customer}
              current_user={this.props.current_user}
              showCloseReturnModal={this.showCloseReturnModal}
              sendEmail={this.sendEmail}
              showSendEmail={showSendEmail}
              showInstantStoreCreditCaptureAuthorizedPaymentModal={
                this.showInstantStoreCreditCaptureAuthorizedPaymentModal
              }
              portalMagicLink={this.props.portal_magic_link}
              canClose={this.props.can_close}
              canCancel={this.props.can_cancel}
              isCompletionInProgress={this.isCompletionInProgress()}
            />
          </div>
        </div>

        <CreateLabelModal
          t={this.state.t}
          showModal={this.state.showModal}
          ModelClose={this.ModelClose}
          isEditCustomer={this.state.edit_customer}
          isEditDhlReceiverId={this.state.edit_dhl_receiver_id}
          labelCustomerInputHandler={this.labelCustomerInputHandler}
          labelReturnAddressInputHandler={this.labelReturnAddressInputHandler}
          editCustomer={this.editCustomer}
          return_label_customer={this.state.return_label_customer}
          dhl_receiver_id={this.state.return_address.dhl_receiver_id}
          country_iso={this.state.country_iso}
          editDhlReceiverId={this.editDhlReceiverId}
          labelFieldsPresent={this.labelFieldsPresent}
          create_dhl_label_spinner={this.state.create_dhl_label_spinner}
          createLable={this.createLable}
          selectCountry={this.selectCountry}
          countryOptions={this.state.countryOptions}
          backEditLabel={this.backEditLabel}
          saveEditLabel={this.saveEditLabel}
          returnAddress={this.state.return_address}
          editReturnAddress={this.editReturnAddress}
          isEditReturnAddress={this.state.edit_return_address}
          labelTypeHandler={this.labelTypeHandler}
          labelType={this.state.label_type}
          selectReturnAddressCountry={this.selectReturnAddressCountry}
        />

        <Modal
          isOpen={this.state.showImageModal}
          onRequestClose={this.closeImageModal}
          style={{
            content: {
              maxWidth: "1000px",
              maxHeight: "640px",
              margin: "auto",
              padding: "0",
              position: "relative",
              overflow: "",
            },
            overlay: {
              background: "#00000080",
            },
          }}
        >
          <div className="absolute -top-4 right-2 cursor-pointer">
            <button
              className="flex items-center justify-center border rounded-full bg-white w-8 h-8 focus:outline-none text-gray-725"
              onClick={this.closeImageModal}
            >
              <ModelCross />
            </button>
          </div>

          <div className="flex items-center justify-center  border rounded max-h-640px">
            <img className="max-h-635px" src={this.state.imageUrl} alt="img" />
          </div>
        </Modal>

        <ReturnCloseModal
          isOpen={this.state.showCloseReturnModal}
          closeModal={this.closeReturnModal}
          markStatus={this.markStatus}
          t={this.state.t}
        />

        <InstantStoreCreditCaptureAuthorizedPaymentModal
          isOpen={this.state.instantStoreCreditCaptureAuthorizedPaymentModal}
          closeModal={this.closeInstantStoreCreditCaptureAuthorizedPaymentModal}
          okButtonName={
            this.state
              .instantStoreCreditCaptureAuthorizedPaymentModalOkButtonName
          }
          onOkButtonClick={
            this.state
              .instantStoreCreditCaptureAuthorizedPaymentModalOkButtonCallback
          }
          t={this.state.t}
        />

        <InstantStoreCreditAuthorizedPaymentModal
          isOpen={this.state.instantStoreCreditAuthorizedPaymentModal}
          closeModal={this.closeInstantStoreCreditAuthorizedPaymentModal}
          t={this.state.t}
        />
      </div>
    );
  }
}
