import React from "react";

import { getXCSRFToken } from "../../util/getXCSRFToken";

import { SuccessToast } from "../common/SuccessToast";
import { ErrorToast } from "../common/ErrorToast";

import EmailData from "./emailData";

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      t: this.props.t,
      selectedTranslation:
        props.selectedEmailTemplate.email_template_translations_attributes[0],
      selectedTranslationIndex: 0,
    };

    this.liquad_syntaxes = [
      "{{ checkout_root_url }} [DEPRECATED]",
      "{{ checkout_login_url }}",
      "{{ primary_color }}",
      "{{ secondary_color }}",
      "{{ order_number }}",
      "{{ rma }}",
      "{{ order_name }}",
      "{{ return_address_company }}",
      "{{ return_address_last_name }}",
      "{{ return_address_street }}",
      "{{ return_address_street_no }}",
      "{{ return_address_city }}",
      "{{ return_address_zip_code }}",
      "{{ return_address_country_iso2 }}",
      "{{ return_address_country }}",
      "{{ shipment_tracking_number }} [DEPRECATED]",
      "{{ customer_name }}",
      "{{ customer_first_name }}",
      "{{ customer_last_name }}",
      "{{ shipment_label }} [DEPRECATED]",
      "{{ shipment_qrcode }} [DEPRECATED]",
      "{{ shipments }}",
      "{{ shipment.tracking_number }}",
      "{{ shipment.label_url }}",
      "{{ shipment.qrcode_url }}",
      "{{ return_method }}",
      "{{ return_method_carrier }}",
      "{{ carrier_manual }}",
      "{{ choose_own_carrier }}",
      "{{ our_customer_service_will_get_back_to_you }}",
      "{{ return_in_store }}",
      "{{ return_type }} [DEPRECATED]",
      "{{ items }}",
      "{{ item.name }}",
      "{{ item.sku }}",
      "{{ item.ean }}",
      "{{ item.image }}",
      "{{ item.quantity }}",
      "{{ item.return_reason_name }}",
      "{{ item.return_reason_name_de }}",
      "{{ item.return_type }}",
      "{{ merchant_name }}",
      "{{ merchant_subdomain }}",
      "{{ merchant_support_email }}",
      "{{ merchant_logo_url }}",
      "{{ amount_refunded }}",
    ];
  }

  saveChanges = (id) => {
    let body = this.props.selectedEmailTemplate;
    fetch(`/email_templates/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": getXCSRFToken(),
      },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.status === 200) {
        response.json().then((responseJson) => {
          SuccessToast({ toastId: "UpdateEmailTemplateSuccess" });
          this.props.updateEmailTemplate();
        });
      } else {
        response.json().then((responseJson) => {
          ErrorToast({
            toastId: "UpdateEmailTemplateError",
            message: responseJson.error,
          });
        });
      }
    });
  };

  setSelectedTranslation = (translation, index) => {
    this.setState({
      selectedTranslation: translation,
      selectedTranslationIndex: index,
    });
  };

  render() {
    return (
      <div className="font-inter">
        <div className="flex justify-between items-center px-8 mb-6">
          <div className="font-medium text-xs leading-4">
            <p>
              <span className="text-gray-525">
                <a href="/settings">{this.state.t.settings} / </a>
              </span>

              <span className="text-gray-525">
                <span
                  className="cursor-pointer"
                  onClick={this.props.closeEditEmailTemplate}
                >
                  {this.state.t.notifications}
                </span>{" "}
                /{" "}
              </span>

              <span className="text-gray-725 text-xs13 capitalize">
                {this.props.selectedEmailTemplate.case === "refunded"
                  ? "completed"
                  : this.props.selectedEmailTemplate.case}
              </span>
            </p>
          </div>

          <div className="font-inter">
            <a className="focus:outline-none" href="/users/edit">
              <p className="font-semibold text-xs14 leading-5 text-right text-gray-825">
                {this.props.current_user.first_name + " "}
                {this.props.current_user.last_name}
              </p>

              <p className="font-normal text-xs leading-4 text-right text-gray-625">
                {this.props.current_user.email}
              </p>
            </a>
          </div>
        </div>

        <div className="flex flex-row items-center justify-between border-t border-b py-5 px-8">
          <div>
            <h5 className="font-semibold text-base leading-6 text-gray-975">
              {this.state.t.edit_notification}
            </h5>
          </div>

          <div className="items-center mr-8">
            <div className="relative inline-block w-12 mr-5 align-middle select-none transition duration-200 ease-in">
              <input
                className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                type="checkbox"
                name="is_active"
                onChange={this.props.emailTemplateCheckboxHandler}
                checked={this.props.selectedEmailTemplate.is_active}
              />

              <label
                htmlFor="toggle"
                className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
              />
            </div>

            <button
              className="bg-green-800 rounded-lg pl-4 pr-4 pb-2 pt-2 font-semibold text-sm leading-5 text-white border border-green-800 hover:bg-green-825 focus:outline-none focus:border-green-825"
              onClick={() =>
                this.saveChanges(this.props.selectedEmailTemplate.id)
              }
            >
              {this.state.t.save_changes}
            </button>
          </div>
        </div>

        <div className="flex px-8">
          <div className="pr-6 border-r w-97% min-h-screen pt-5">
            <h1 className="font-semibold text-sm leading-5 text-gray-975 mb-5">
              {this.state.t.common_details}
            </h1>

            <div className="mb-8">
              <p className="font-medium text-xs leading-4 flex items-center text-gray-725 mb-1 capitalize">
                {this.state.t.case}
              </p>

              <div className="py-10px px-4 w-full box-border rounded-lg border border-gray-325 font-medium text-sm leading-5 text-gray-875 capitalize">
                {this.props.selectedEmailTemplate.case === "refunded"
                  ? "completed"
                  : this.props.selectedEmailTemplate.case}
              </div>
            </div>

            <h1 className="font-semibold text-sm leading-5 text-gray-975 mb-5">
              {this.state.t.localized_details}
            </h1>

            <div className="border-b mb-6">
              {this.props.selectedEmailTemplate.email_template_translations_attributes.map(
                (translation, index) => (
                  <button
                    key={index}
                    className={`${
                      this.state.selectedTranslation.id === translation.id
                        ? "border-b-2 inline-block border-gray-825 font-semibold text-gray-825"
                        : "font-medium text-gray-625"
                    } w-max pb-4 font-inter text-sm mr-10 leading-5 focus:outline-none`}
                    onClick={() =>
                      this.setSelectedTranslation(translation, index)
                    }
                  >
                    {translation.language_name}
                  </button>
                )
              )}
            </div>

            <EmailData
              selectedTranslation={this.state.selectedTranslation}
              selectedTranslationIndex={this.state.selectedTranslationIndex}
              translationInputChangeHandler={
                this.props.translationInputChangeHandler
              }
              t={this.state.t}
            />
          </div>

          <div className="pl-6 py-5 pr-8">
            <div className="border-b mb-3 pb-3">
              <h1 className="font-semibold text-sm leading-5 text-gray-975 mb-3">
                {this.state.t.email_tags}
              </h1>

              <p className="font-medium text-xs leading-4 text-gray-725">
                {this.state.t.liquad_syntax_text}{" "}
                <a
                  href="https://shopify.github.io/liquid/basics/introduction/"
                  target="_blank"
                  className="text-blue-625"
                >
                  {this.state.t.here}
                </a>
              </p>
            </div>

            {this.liquad_syntaxes.map((liquad_syntax, index) => (
              <p className="text-sm leading-5 text-gray-725 mb-3" key={index}>
                {liquad_syntax}
              </p>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
