import React from "react";
import cx from "classnames";
import { Typography } from "../../../../../ui-kit/components/Typography/Typography";
export const ShowReturn = (props) => {
  const {
    className,
    title,
    info,
    returnMethodImage,
    shipmentLabelUrl,
    trackingLink,
    customerDetailUrl,
    titleClass = "",
    infoClass = "",
    children,
  } = props;

  const titleTypography = () => {
    return (
      <Typography
        className={cx(titleClass)}
        element="div"
        weight="medium"
        size="xs13"
        height="4"
        color="gray-525"
      >
        {title}
      </Typography>
    );
  };
  const infoTypography = () => {
    return (
      <Typography
        className={cx(infoClass)}
        element="div"
        weight="medium"
        size="xs13"
        height="4"
        color={shipmentLabelUrl || trackingLink ? "blue-675" : "gray-825"}
      >
        {info}
      </Typography>
    );
  };
  return (
    <div className={cx(className, "flex justify-between")}>
      {titleTypography()}
      {returnMethodImage ? (
        <div className="flex items-center w-7/12">
          <img className="w-4 h-6x mr-2" src={returnMethodImage} alt="img" />
          {infoTypography()}
        </div>
      ) : shipmentLabelUrl ? (
        <a
          className="flex items-center w-7/12"
          href={shipmentLabelUrl}
          target="_blank"
          rel="noreferrer"
        >
          {children}
          {infoTypography()}
        </a>
      ) : trackingLink ? (
        <a
          className="flex items-center w-7/12"
          href={trackingLink}
          target="_blank"
          rel="noreferrer"
        >
          {children}
          {infoTypography()}
        </a>
      ) : customerDetailUrl ? (
        <div className="flex items-center w-7/12">
          {infoTypography()}
          <a
            className="ml-4"
            href={customerDetailUrl}
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        </div>
      ) : (
        infoTypography()
      )}
    </div>
  );
};
