import React from "react";
import SearchInput from "./SearchInput";

const EmailAndInvoiceSearch = ({
  email,
  invoiceNumber,
  onChange,
}) => (
  <>
    <SearchInput
      type="email"
      placeholder="Email"
      value={email}
      onChange={(e) => onChange({
        ...email,
        invoiceNumber,
        email: e.target.value,
      })}
    />
    <SearchInput
      type="text"
      placeholder="Invoice Number"
      value={invoiceNumber}
      onChange={(e) => onChange({
        email,
        invoiceNumber: e.target.value,
      })}
    />
  </>
);

export default EmailAndInvoiceSearch;
