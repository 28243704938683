export const useItemSelection = () => {
  const inputNames = [
    "item",
    "how_many_items_question",
    "available_for_return",
    "select_quantity_title",
    "quantity",
    "returning_quantity",
    "next",
    "registered_returns",
    "return_labels",
    "eligible_items_text",
    "not_eligible_items_text",
    "label_title",
    "label_address_title",
    "no_of_items",
    "download_label",
    "return_reasons_title",
    "returning",
    "reason",
    "choose_reason",
    "describe_problem_text",
    "describe_problem_textarea_text",
    "browse_or_drag_text",
    "browse",
    "upload_image_text",
    "tell_us_more_text",
    "see_return_status",
    "select_remaining_item_text",
    "return_items",
    "initiated_items_text",
    "close",
    "refund_tracking_registered",
    "refund_tracking_arrived",
    "refund_tracking_checked",
    "refund_tracking_refunded",
    "refund_tracking_cancelled",
    "exchange_variant_title",
    "refund",
    "exchange",
    "exchange_for",
    "exchange_same_item_extra_text",
    "load_more",
    "exchange_same_item",
    "exchange_same_item_text",
    "required_field",
    "remove_item",
    "more_options",
    "continue",
    "trade_in",
    "trade_in_items_text",
    "variant_not_eligible_for_exchange",
    "exchange_select_placeholder",
  ];

  return {
    inputNames,
  };
};
